<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Config
      </app-btn>
    </v-col>
    <material-card icon="mdi-account-group" icon-small color="primary" :title="selectedCompanyName + ' Configs'">
      <v-card-text>
        <v-text-field v-model="search" append-icon="mdi-magnify" class="ml-auto" hide-details label="Search records"
          single-line style="max-width: 250px" />

        <v-divider class="mt-3" />

        <editable-config-table v-model="items" :search.sync="search" @update="update" @remove="remove">
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="1350px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <div class="d-flex align-center justify-center ma-1 mb-1"
                      style="padding: 15px 20px; border-radius: 5px; background: #f5f5f5;">
                      <v-autocomplete v-model="referenceId" label="Reference" :items="companies" item-text="pathStr"
                        item-value="id" hide-details @change="chooseReference" />
                      <!-- <app-btn
                        class="mt-1 ml-5"
                        @click="chooseReference"
                      >
                        Choose
                      </app-btn> -->
                    </div>
                    <multi-config-form v-model="editedItems" />
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </editable-config-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import EditableConfigTable from './components/config/EditableConfigTable.vue'
import MultiConfigForm from './components/config/MultiConfigForm.vue'
import { get } from 'vuex-pathify'
import store from '../store'

export default {
  name: 'DataTablesView',
  components: { MultiConfigForm, EditableConfigTable },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      length15: (value) => value.length <= 15 || 'Maximum 15 characters.',
    },
    dialog: false,
    items: [],
    editedIndex: -1,
    editedItems: [],
    search: undefined,
    referenceId: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Add New Config' : 'Edit Config'
    },
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
    companies() {
      return store.getters['company/getCompanies']
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    selectedCompanyId() {
      this.items = []
      this.getCompanyConfigurations(this.selectedCompanyId).then(data => {
        this.items = data
      })
    },
  },

  created() {
    this.getCompanyConfigurations(this.selectedCompanyId).then(data => {
      this.items = data
    })
  },

  methods: {
    async getCompanyConfigurations(id) {
      try {
        const res = await this.$http(`/CompanyConfiguration?companyId=${id}`)
        if (res.data?.success) {
          return res.data.data || []
        }
      } catch (error) {

      }

      return []
    },
    close() {
      this.editedItems = []
      this.dialog = false
    },

    save() {
      if (!this.editedItems || !this.editedItems.length) {
        return
      }

      if (this.selectedCompanyId === 0) {
        this.$toast.error('Company not found.', 'Error', {
          position: 'topRight',
        })
        return
      }

      this.$http.post('/CompanyConfiguration/CreateCompanyConfigurations', {
        companyId: this.selectedCompanyId,
        createConfigurations: this.editedItems,
      }).then((response) => {
        if (response.data.success) {
          this.$toast.success('Config added successfully.', 'Success', {
            position: 'topRight',
          })
          this.items.unshift(...response.data.data)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })

      this.close()
    },
    update(item) {
      if (!item.configurationId && item.configurationId === 0) {
        return
      }

      this.$http.put(`/DeviceConfiguration/${item.configurationId}`, item).then(response => {
        if (response.data.success) {
          this.$toast.success('Config updated successfully.', 'Success', {
            position: 'topRight',
          })
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },
    remove(item) {
      this.$http.delete(`/CompanyConfiguration/DeleteCompanyConfiguration?id=${item.id}`).then(response => {
        if (response.data.success) {
          this.$toast.success('Config removed successfully.', 'Success', {
            position: 'topRight',
          })

          const index = this.items.indexOf(item)
          this.items.splice(index, 1)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },

    chooseReference() {
      this.editedItems = []

      if (!this.referenceId || this.referenceId === 0) {
        return
      }

      this.getCompanyConfigurations(this.referenceId).then(data => {
        this.editedItems = data.map(x => ({
          name: x.name,
          type: x.type,
          value: x.value,
          description: x.description,
        }))
      })
    },
  },
}
</script>
